// import * as bootstrap from 'bootstrap';
// import '../scss/cover.css';
import p5 from 'p5';

let s = (sk) => {
	let canvas;
	let incanvas = false;
	sk.setup = () => {
		canvas = sk.createCanvas(300, 300).parent('psketch');
		canvas.mouseOver(() => {
			incanvas = true;
		})
		canvas.mouseOut(() => {
			incanvas = false;
		})
		sk.background(40, 20, 255);
	}
	sk.draw = () => {
		if (sk.mouseIsPressed && incanvas) {
			sk.fill(0);
			sk.ellipse(sk.mouseX, sk.mouseY, 80, 80);
			console.log("hehe");
		}
	}
	sk.restart = () => {
		sk.clear();
		sk.setup();
	}
	sk.kill = () => {
		canvas.remove();
	}
}
const P5 = new p5(s,);
document.getElementById("processing").onclick = function () {
	P5.kill();
}
document.getElementById("launcher").onclick = function () {
	P5.setup();
	console.log(P5);
}